export const getPermissions = (label, user) => {
  const permissions = user?.profile?.label;
  switch (label) {
    case 'dashboard':
      return true;
    case 'myLeaves':
      return true;
    case 'planning':
      return (
        permissions === 'STANDARDMANAGER' ||
        permissions === 'ADMINISTRATEURMANAGER' ||
        permissions === 'ADMINISTRATEUR' ||
        permissions === 'DIRECTOR'
      );
    case 'teams':
      return (
        permissions === 'STANDARDMANAGER' ||
        permissions === 'ADMINISTRATEURMANAGER' ||
        permissions === 'DIRECTOR'
      );
    case 'validation':
      return (
        permissions === 'STANDARDMANAGER' ||
        permissions === 'ADMINISTRATEURMANAGER' ||
        'DIRECTOR'
      );
    case 'sendToAccounting':
      return (
        permissions === 'ADMINISTRATEUR' ||
        permissions === 'ADMINISTRATEURMANAGER'
      );
    case 'documentation':
      return true;
    case 'setting':
      return (
        permissions === 'ADMINISTRATEURMANAGER' ||
        permissions === 'ADMINISTRATEUR'
      );
    case 'mobileApp':
      return true;
    default:
      return false;
  }
};
