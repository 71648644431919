exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-[id]-jsx": () => import("./../../../src/pages/account/[id].jsx" /* webpackChunkName: "component---src-pages-account-[id]-jsx" */),
  "component---src-pages-alert-modal-jsx": () => import("./../../../src/pages/AlertModal.jsx" /* webpackChunkName: "component---src-pages-alert-modal-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-documentation-jsx": () => import("./../../../src/pages/documentation.jsx" /* webpackChunkName: "component---src-pages-documentation-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-leaves-jsx": () => import("./../../../src/pages/leaves.jsx" /* webpackChunkName: "component---src-pages-leaves-jsx" */),
  "component---src-pages-planning-jsx": () => import("./../../../src/pages/planning.jsx" /* webpackChunkName: "component---src-pages-planning-jsx" */),
  "component---src-pages-request-jsx": () => import("./../../../src/pages/request.jsx" /* webpackChunkName: "component---src-pages-request-jsx" */),
  "component---src-pages-send-to-accounting-exports-history-jsx": () => import("./../../../src/pages/sendToAccounting/exportsHistory.jsx" /* webpackChunkName: "component---src-pages-send-to-accounting-exports-history-jsx" */),
  "component---src-pages-send-to-accounting-jsx": () => import("./../../../src/pages/sendToAccounting.jsx" /* webpackChunkName: "component---src-pages-send-to-accounting-jsx" */),
  "component---src-pages-send-to-accounting-request-processed-jsx": () => import("./../../../src/pages/sendToAccounting/requestProcessed.jsx" /* webpackChunkName: "component---src-pages-send-to-accounting-request-processed-jsx" */),
  "component---src-pages-send-to-accounting-request-to-process-jsx": () => import("./../../../src/pages/sendToAccounting/requestToProcess.jsx" /* webpackChunkName: "component---src-pages-send-to-accounting-request-to-process-jsx" */),
  "component---src-pages-setting-counters-jsx": () => import("./../../../src/pages/setting/counters.jsx" /* webpackChunkName: "component---src-pages-setting-counters-jsx" */),
  "component---src-pages-setting-documentations-jsx": () => import("./../../../src/pages/setting/documentations.jsx" /* webpackChunkName: "component---src-pages-setting-documentations-jsx" */),
  "component---src-pages-setting-jsx": () => import("./../../../src/pages/setting.jsx" /* webpackChunkName: "component---src-pages-setting-jsx" */),
  "component---src-pages-setting-leaves-jsx": () => import("./../../../src/pages/setting/leaves.jsx" /* webpackChunkName: "component---src-pages-setting-leaves-jsx" */),
  "component---src-pages-setting-new-leaves-jsx": () => import("./../../../src/pages/setting/NewLeaves.jsx" /* webpackChunkName: "component---src-pages-setting-new-leaves-jsx" */),
  "component---src-pages-teams-jsx": () => import("./../../../src/pages/teams.jsx" /* webpackChunkName: "component---src-pages-teams-jsx" */),
  "component---src-pages-unauthorized-jsx": () => import("./../../../src/pages/unauthorized.jsx" /* webpackChunkName: "component---src-pages-unauthorized-jsx" */),
  "component---src-pages-validation-jsx": () => import("./../../../src/pages/validation.jsx" /* webpackChunkName: "component---src-pages-validation-jsx" */),
  "component---src-pages-validation-request-cancel-jsx": () => import("./../../../src/pages/validation/requestCancel.jsx" /* webpackChunkName: "component---src-pages-validation-request-cancel-jsx" */),
  "component---src-pages-validation-request-processed-jsx": () => import("./../../../src/pages/validation/requestProcessed.jsx" /* webpackChunkName: "component---src-pages-validation-request-processed-jsx" */),
  "component---src-pages-validation-request-to-process-jsx": () => import("./../../../src/pages/validation/requestToProcess.jsx" /* webpackChunkName: "component---src-pages-validation-request-to-process-jsx" */)
}

